<template>
  <div v-if="paragraphs?.length || isEditing" class="paragraph overflow-hidden">
    <div class="relative m-auto max-w-[1036px]">
      <BlokkliField
        name="field_paragraphs"
        :list="paragraphs"
        list-class="!absolute bottom-0 left-0 w-full flex gap-24 z-50"
        proxy-mode
      >
        <template #after="{ items }">
          <OrisSwiper
            v-bind="attributes"
            class="!overflow-visible"
            :class="{
              'pointer-events-none': isEditing,
            }"
            :scrollbar-margin-top="59"
          >
            <SwiperSlide v-for="item in items" :key="item.uuid" class="!h-auto">
              <ParagraphRelatedSlider
                v-bind="getSlideProps(item)"
                :class="{
                  'pb-[120px]': (paragraphs?.length ?? 0) > 1,
                }"
              />
            </SwiperSlide>

            <div
              v-if="items.length > 1"
              ref="paginationEl"
              class="slider-pagination absolute bottom-[53px] z-50 flex w-full items-center justify-center gap-8 px-16 md:hidden"
            />

            <div
              v-if="paragraphs?.length && paragraphs.length > 1"
              class="mx-auto -mr-8 mt-16 hidden justify-end md:flex"
            >
              <button
                ref="prevEl"
                class="p-8 text-grey-dark-01 hover:text-black"
                aria-label="Previous slide"
              >
                <SpriteSymbol name="arrow-left" class="h-24 w-24" />
              </button>
              <button
                ref="nextEl"
                class="p-8 text-grey-dark-01 hover:text-black"
                aria-label="Next slide"
              >
                <SpriteSymbol name="arrow-right" class="h-24 w-24" />
              </button>
            </div>

            <div
              v-if="items.length === 0 && isEditing"
              class="flex min-h-48 items-center justify-center bg-grey-light-03 pb-4"
            >
              <p>There are no "Related Slider (Teaser)" in here yet.</p>
            </div>
          </OrisSwiper>
        </template>
      </BlokkliField>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Scrollbar, Navigation, Pagination } from 'swiper/modules'
import type { FieldListItem } from '#blokkli/types'
import {
  type ParagraphFromLibraryFragment,
  type ParagraphRelatedSliderListFragment,
} from '#graphql-operations'
import 'swiper/scss'

const { isEditing } = defineBlokkli({
  bundle: 'related_slider_list',
  editor: {
    addBehaviour: 'no-form',
    disableEdit: true, // There is nothing to edit in the Related Slider List. Only the individual items are editable.
  },
})

defineProps<{
  paragraphs: ParagraphRelatedSliderListFragment['paragraphs']
}>()

const paginationEl = ref<HTMLElement>()
const prevEl = ref<HTMLButtonElement>()
const nextEl = ref<HTMLButtonElement>()

const { isMobile } = useViewport()

type SwiperProps = InstanceType<typeof Swiper>['$props']

const attributes = computed<SwiperProps | undefined>(() => {
  return {
    modules: [Scrollbar, Navigation, Pagination],
    slidesPerView: 1,
    spaceBetween: isMobile.value ? 16 : 32,
    pagination: {
      enabled: isMobile.value,
      el: paginationEl.value ?? null,
      bulletActiveClass: '!flex-[2] !bg-black',
      bulletClass:
        'transition-all ease-swing duration-300 h-2 bg-grey-light-01 flex-1',
      clickable: true,
    },
    // `enabled: !isMobile.value` doesn't work, if the scrollbar prop is present the scrollbar container will always be rendered (bug in Swiper)
    scrollbar: !isMobile.value && {
      enabled: true,
      snapOnRelease: false,
      draggable: true,
      horizontalClass: 'swiper-scrollbar',
    },
    navigation: {
      enabled: !isMobile.value,
      prevEl: prevEl.value ?? null,
      nextEl: nextEl.value ?? null, // swiper always needs null on elements to correctly work. undefined does not work.
      disabledClass: '!text-grey-mid',
    },
  }
})

const getSlideProps = (item: FieldListItem) => {
  if (item.bundle === 'from_library') {
    return (item.props as ParagraphFromLibraryFragment).libraryItem?.block
      ?.props
  }
  return item.props
}
</script>
