import type { GraphqlResponse } from '#graphql-middleware-server-options-build'
import type {
  AddToCartMutation,
  CartFragment,
  OrderItemFragment,
  RemoveFromCartMutation,
} from '#graphql-operations'
import { falsy } from '@/helpers/type'

type CartMutationResult = {
  errors?: string[]
}

type Cart = {
  orderItems: OrderItemFragment[]
}

type UseCart = {
  /**
   * Add a product with the given SKU to the cart.
   */
  add: (sku: string) => Promise<CartMutationResult>

  /**
   * Remove the order item with the given ID from the cart.
   */
  remove: (orderItemId: string) => Promise<CartMutationResult>

  /**
   * Load the cart. The cart is loaded initially when the app is mounted and
   * is updated through mutations. It should not be needed to call this method
   * manually.
   */
  load: () => Promise<void>

  /**
   * Get an order item from the cart for the given SKU.
   */
  getOrderItemForSku: (sku: string) => OrderItemFragment | undefined

  /**
   * The amount of order items in the cart.
   */
  count: ComputedRef<number>
}

export default function (): UseCart {
  const country = useCurrentCountry()
  const cart = useState<Cart>('cart', () => {
    return {
      orderItems: [],
    }
  })

  // Updates the cart state from either the load query or from a mutation.
  const updateCart = (v: CartFragment | null | undefined) => {
    cart.value.orderItems = (v?.orderItems || []).filter(falsy)
  }

  // Handles updating the state from a mutation.
  const handleMutation = (
    data: GraphqlResponse<AddToCartMutation | RemoveFromCartMutation>,
  ) => {
    updateCart(data.data.mutation?.order)
    return {
      errors: data.data.mutation?.errors,
    }
  }

  const add = (sku: string) =>
    useGraphqlMutation('addToCart', {
      skus: [sku],
      country: country.value,
    }).then(handleMutation)

  const remove = (id: string) =>
    useGraphqlMutation('removeFromCart', {
      id,
      country: country.value,
    }).then(handleMutation)

  const load = async () => {
    const data = await useGraphqlQuery('cart').then(
      (v) => v.data.commerceGetCart?.order,
    )
    updateCart(data)
  }

  const getOrderItemForSku = (sku: string) =>
    cart.value.orderItems.find((v) => v.purchasedEntity?.sku === sku)

  const count = computed(() => cart.value.orderItems.length)

  return {
    add,
    remove,
    load,
    getOrderItemForSku,
    count,
  }
}
