export const IS_DEV = process.env.NODE_ENV === 'development'
export const IS_LANDO = process.env.LANDO === 'ON'
export const DRUPAL_HOST = process.env.BACKEND_URL ?? 'http://appserver'
export const ROKKA_HOST = process.env.ROKKA_HOST ?? 'oris-local.rokka.io'

// Utility to assist in decoding a packed JSON variable.
function readBase64Json(name: string) {
  const value = process.env[name]
  if (value) {
    try {
      return JSON.parse(Buffer.from(value, 'base64').toString())
    } catch (_err) {}
  }
  return {}
}

interface PlatformRoute {
  primary: boolean
  id: string | null
  type: string
  upstream: string
  original_url: string
}

const routes: Record<string, PlatformRoute> = readBase64Json('PLATFORM_ROUTES')

function getBaseUrl() {
  const urls = Object.keys(routes)
  for (const url of urls) {
    const route = routes[url]
    if (route.id === 'main') {
      return url.replace(/\/$/, '')
    }
  }

  if (IS_LANDO) {
    return `https://oris.lndo.site`
  }

  return process.env.CYPRESS_BASE_URL
}

/**
 * The URL on which this app is running.
 *
 * On a platform environment this is the url defined by the "cart" route from
 * .platform/routes.yaml.
 */
export const BASE_URL = getBaseUrl()
