export default defineNuxtRouteMiddleware(async () => {
  const user = await useDrupalUser()

  if (!user?.value.canAccessToolbar) {
    return abortNavigation({
      statusCode: 404,
      statusMessage: 'Page Not Found',
    })
  }
})
