/**
 * Basic implementation of a cached GraphQL query.
 *
 * The query is cached client side in memory indefinitely (or until the user refreshes the page)
 * On server side, the query is cached only for a very short amount of time.
 *
 * This composable will be part of nuxt-graphql-middleware at one point.
 */
import type { FetchOptions } from 'ofetch'
import type { GraphqlMiddlewareQuery } from '#build/nuxt-graphql-middleware/generated-types'

type GraphqlMiddlewareQueryName = keyof GraphqlMiddlewareQuery
type GetQueryArgs<
  T extends GraphqlMiddlewareQueryName,
  M extends GraphqlMiddlewareQuery,
> = M[T][0] extends null
  ? [T]
  : M[T][1] extends false
    ? [T, M[T][0]]
    : [T, M[T][0]?]

type GraphqlResponse<T> = {
  data: T
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errors: any[]
}

type GetQueryResult<
  T extends GraphqlMiddlewareQueryName,
  M extends GraphqlMiddlewareQuery,
> = M[T] extends undefined ? undefined : GraphqlResponse<M[T][2]>

type QueryObjectArgs<
  T extends GraphqlMiddlewareQueryName,
  M extends GraphqlMiddlewareQuery,
> = M[T][0] extends null
  ? {
      name: T
      fetchOptions?: FetchOptions
      variables?: null
    }
  : {
      name: T
      variables: M[T][0]
      fetchOptions?: FetchOptions
    }

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const hasMessengerMessages = (result: GraphqlResponse<any>): boolean => {
  return (
    result.data &&
    'messengerMessages' in result.data &&
    !!result.data.messengerMessages.length
  )
}

/**
 * Performs a normal GraphQL query but caches the response.
 *
 * During SSR, the request is cached for 5 minutes. Client side, the response
 * is cached indefinitely or until the max amount of cache items has been
 * reached. In this case the least recently used response is removed from the
 * cache.
 */
export async function useCachedGraphqlQuery<
  T extends GraphqlMiddlewareQueryName,
>(
  ...args:
    | GetQueryArgs<T, GraphqlMiddlewareQuery>
    | [QueryObjectArgs<T, GraphqlMiddlewareQuery>]
): Promise<GetQueryResult<T, GraphqlMiddlewareQuery>> {
  const language = useCurrentLanguage()
  const country = useCurrentCountry()
  const event = useRequestEvent()

  // Build the cache key based on the provided arguments and the current country and language.
  const key = JSON.stringify(args) + language.value + country.value

  // We use nuxt-multi-cache on the server.
  if (process.server) {
    const { value, addToCache } = await useDataCache<
      GetQueryResult<T, GraphqlMiddlewareQuery> | undefined
    >(key, event)

    // Exists in cache, so we return it.
    if (value) {
      return value
    }

    // @ts-ignore
    const result = await useGraphqlQuery(...args)

    // Don't ever cache GraphQL queries that contain messenger messages.
    // Users with a session cookie should never end up in SSR, so this
    // check is technically not needed, but we keep it - just in case.
    // Or else we might serve such messages for all visitors.
    if (!hasMessengerMessages(result)) {
      // Cache all responses for a maximum of 5 minutes.
      // @ts-ignore
      addToCache(result, [], 60 * 5)
    }

    // @ts-ignore
    return result
  }

  // Get the client cache singleton.
  const { $clientCache } = useNuxtApp()

  const cached = $clientCache.get<
    Promise<GetQueryResult<T, GraphqlMiddlewareQuery>> | undefined
  >(key)

  // If we already have a request for this key, return it.
  if (cached) {
    return cached
  }

  // @ts-ignore
  const request = useGraphqlQuery(...args)

  // Cache the entire request promise. That way we can also return pending
  // promises to prevent making the same request more than once.
  $clientCache.set(key, request)

  // @ts-ignore
  return request
}
