<template>
  <div class="flex-1 bg-grey-light-02 p-16">
    <div class="flex h-full gap-12">
      <div>
        <MediaImage
          v-if="image"
          v-blokkli-droppable:field_media_image
          class="h-full w-112"
          img-class="w-full h-full object-cover"
          v-bind="image"
        />
      </div>
      <div class="flex-1">
        <div class="float-right text-xl font-bold">{{ index + 1 }}</div>
        <div v-blokkli-editable:field_overline class="text-sm uppercase">
          {{ overline }}
        </div>
        <div v-blokkli-editable:field_headline class="text-xl uppercase">
          {{ headline }}
        </div>
        <div
          v-blokkli-editable:field_description="{ type: 'frame' }"
          class="rich-text text-sm"
          v-html="description"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
/**
 * This component is only rendered during editing.
 * The actual slide component is defined in index.vue.
 */
import type { ParagraphRelatedSliderFragment } from '#graphql-operations'

const { index } = defineBlokkli({
  bundle: 'related_slider',
})

defineProps<{
  overline?: string
  headline?: string
  description?: string
  link?: ParagraphRelatedSliderFragment['link']
  image?: ParagraphRelatedSliderFragment['image']
}>()
</script>
