import { defineVuepalAdapter } from '#vuepal/types'
import { useCurrentLanguage } from '#imports'

export default defineVuepalAdapter(() => {
  return {
    getTranslations() {
      // dummy getTranslations needes since this method only gets removed in vuepal 2.4.0, but is already not used in vuepal 2.3.0
      return Promise.resolve({})
    },
    getStaticNodes() {
      // We don't use the static nodes feature from Vuepal, so we can just
      // return an empty array here.
      return Promise.resolve([])
    },
    async getAdminMenu() {
      const v = await useCachedGraphqlQuery('adminToolbar')
      return v?.data
    },
    async getLocalTasks(path: string) {
      const v = await useCachedGraphqlQuery('localTasks', { path })

      if (v?.data.route && 'localTasks' in v.data.route) {
        return v.data.route.localTasks
      }

      return []
    },
    getCurrentLanguage() {
      return useCurrentLanguage()
    },
  }
})
