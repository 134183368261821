<template>
  <div
    class="paragraph paragraph-content-text-image layout-grid"
    :class="displayClass"
  >
    <!-- We are using `items-end` instead of `items-baseline` due to a known bug in Safari that causes layout issues when using `items-baseline`. -->
    <div
      class="grid-area-m grid grid-cols-4 items-end gap-16 self-end md:gap-24"
    >
      <MediaImage
        v-blokkli-droppable:field_image_large
        v-bind="imageLarge"
        class="col-span-3"
        img-class="w-full"
        :image-style="imageStyleLarge"
        :class="options.display === 'left' && 'md:order-last'"
      />
      <MediaImage
        v-blokkli-droppable:field_image_small
        v-bind="imageSmall"
        :image-style="imageStyleSmall"
        img-class="w-full"
      />
    </div>

    <div class="grid-area-t" :class="offsetPadding">
      <div
        v-blokkli-editable:field_overline
        class="mb-16 text-base uppercase md:mb-24"
      >
        {{ overline }}
      </div>
      <Text
        v-blokkli-editable:field_headline_long
        class="typo-headline-1 mb-24 uppercase md:mb-32"
      >
        {{ headline }}
      </Text>
    </div>

    <div
      class="grid-area-c justify flex flex-col mobile-only:mb-72"
      :class="offsetPadding"
    >
      <div
        v-blokkli-editable:field_rich_text="{ type: 'frame' }"
        class="typo-body-2 rich-text lg:mb-0 lg:pl-[110px]"
        v-html="description"
      />

      <ButtonsLinkButton
        v-if="url"
        class="link-button mt-40 md:mt-56 lg:ml-[110px]"
        :url="url"
      >
        {{ $texts('readMore', 'Read more') }}
      </ButtonsLinkButton>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { ParagraphContentTextImageFragment } from '#graphql-operations'

const { $texts } = useEasyTexts()

const { options } = defineBlokkli({
  bundle: 'content_text_image',
  options: {
    display: {
      type: 'radios',
      label: 'Display',
      default: 'left',
      displayAs: 'icons',
      options: {
        left: { icon: 'icon-blokkli-option-text-image-left', label: 'Left' },
        right: { icon: 'icon-blokkli-option-text-image-right', label: 'Right' },
      },
    },
  },
})

const props = defineProps<{
  overline?: ParagraphContentTextImageFragment['overline']
  headline?: ParagraphContentTextImageFragment['headline']
  description?: ParagraphContentTextImageFragment['description']
  link?: ParagraphContentTextImageFragment['link']
  imageLarge?: ParagraphContentTextImageFragment['imageLarge']
  imageSmall?: ParagraphContentTextImageFragment['imageSmall']
}>()

const imageStyleLarge = defineImageStyle({
  type: 'sizes',
  aspectRatio: 3 / 4,
  sizes: {
    sm: 570,
    md: 425,
    lg: 500,
    xl: 600,
    xxl: 1000,
    xxxl: 1400,
  },
})

const imageStyleSmall = defineImageStyle({
  type: 'pictures',
  pictures: {
    sm: {
      width: 180,
      aspectRatio: 85 / 220,
    },
    md: {
      width: 130,
      aspectRatio: 165 / 331,
    },
    lg: {
      width: 155,
      aspectRatio: 165 / 331,
    },
    xl: {
      width: 185,
      aspectRatio: 165 / 331,
    },
    xxl: {
      width: 326,
      aspectRatio: 165 / 331,
    },
  },
})

/**
 * The special padding to move text elements out of the grid.
 */
const offsetPadding = computed(() => {
  if (options.value.display === 'left') {
    return 'lg:pl-40'
  } else if (options.value.display === 'right') {
    return 'lg:pr-40'
  }
})

const displayClass = computed(
  () => 'paragraph-content-text-image--' + options.value.display,
)

const url = computed(() => props.link?.uri?.path)
</script>

<style lang="postcss">
/* T = Title */
/* C = Content */
/* M = Media (image) */
.paragraph-content-text-image {
  grid-template-areas:
    '. T T T T .'
    '. . C C C .'
    'M M M M M M';
  @screen md {
    grid-template-areas:
      '. T T T T C C C C C C C C .'
      '. T T T T M M M M M M M M M';
  }
}

.paragraph-content-text-image--left {
  @screen md {
    grid-template-rows: 1fr auto auto;
    grid-template-areas:
      'M M M M M M M M . . . . . .'
      'M M M M M M M M T T T T T .'
      'M M M M M M M M C C C C C .';
  }
}

.paragraph-content-text-image--right {
  @screen md {
    grid-template-rows: 1fr auto auto;
    grid-template-areas:
      '. . . . . . M M M M M M M M'
      '. T T T T T M M M M M M M M'
      '. C C C C C M M M M M M M M';
  }
}
</style>
