<template>
  <div v-if="variationsBySize?.[selectedSize]?.length" class="paragraph">
    <div class="layout-grid">
      <div
        class="product-title grid h-full text-5xl font-light uppercase mobile-only:col-span-4 mobile-only:col-start-2 md:col-span-6 md:col-start-2 md:pb-16 md:text-7xl"
      >
        <h2
          v-if="fieldProductReference?.label"
          class="flex self-end md:mb-[-.45em]"
        >
          {{ fieldProductReference?.label }}
        </h2>
      </div>
      <div
        v-if="fieldProductReference?.fieldCollectionPageText"
        class="rich-text col-span-5 col-start-2 grid h-full text-base *:self-end md:col-span-6 md:col-start-8"
        v-html="fieldProductReference?.fieldCollectionPageText"
      />
      <InputDropdown
        v-if="options.length > 2"
        v-model="selectedSize"
        class="col-start-2 mt-40 mobile-only:col-end-[-2] mobile-only:mt-32 md:col-end-6 lg:col-end-4"
        :items="options"
        @update="limit = increment"
      />
    </div>
    <div class="layout-grid">
      <ul
        class="layout-grid-contain my-40 grid gap-24 md:grid-cols-2 lg:grid-cols-3"
      >
        <li
          v-for="variation of variationsBySize?.[selectedSize].slice(0, limit)"
          :key="variation.id"
        >
          <ProductVariationTeaser
            :variation="variation"
            class="h-full bg-grey-light-03"
            :image-style="imageStyle"
          />
        </li>
        <ProductFinderLoadMore
          v-if="variationsBySize?.[selectedSize].length > limit"
          class="col-span-full justify-center text-base"
          @click="limit += increment"
        >
          {{ $texts('productFinder.loadMore', 'Load More') }}
        </ProductFinderLoadMore>
      </ul>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type {
  ParagraphProductFragment,
  ProductFinderItemFragment,
} from '#graphql-operations'
import type { DropdownItem } from '~/components/Input/Dropdown.vue'
import type { AnyProductVariationTeaser } from '~/types/product'

defineBlokkli({ bundle: 'product' })

const { isMobile } = useViewport()
const increment = computed(() => (isMobile.value ? 4 : 6))
const limit = ref(increment.value) // do not init with 0 as otherwise no product teasers will be rendered with JS off and SSR. Relevant for the Google Bot.

onMounted(() => {
  limit.value = increment.value
})

const props = defineProps<ParagraphProductFragment>()
const { $texts } = useNuxtApp()

const { data } = await useAsyncData(
  `productVariations_${props.fieldProductReference?.productId}`,
  async () => {
    const { data } = await useCachedGraphqlQuery('productVariations', {
      id: `${props.fieldProductReference?.productId}`,
    })

    return {
      variations: data.product?.reverseReference?.query?.variations ?? [],
    }
  },
)

const variations =
  data.value && 'variations' in data.value ? data.value.variations : []
const ALL_SIZES = 'all'

const variationsBySize = computed<
  Record<string, Array<AnyProductVariationTeaser | ProductFinderItemFragment>>
>(() => {
  return variations.reduce<
    Record<
      string,
      Array<AnyProductVariationTeaser | ProductFinderItemFragment>
    > & {
      [ALL_SIZES]: Array<AnyProductVariationTeaser | ProductFinderItemFragment>
    }
  >(
    (grouped, variation) => {
      if (!!variation && 'size' in variation && variation?.size) {
        return {
          ...grouped,
          [variation.size]: grouped?.[variation.size]
            ? [...grouped[variation.size], variation]
            : [variation],
          [ALL_SIZES]: [...grouped[ALL_SIZES], variation],
        }
      }
      return grouped
    },
    { [ALL_SIZES]: [] },
  )
})

const options = computed(() => {
  const { [ALL_SIZES]: _ALL_SIZES, ...sizes } = variationsBySize.value
  const sizeOptions = Object.keys(sizes)
    .map<DropdownItem>((o) => ({
      value: o,
      label: o,
    }))
    // Sort options alphabetically
    .sort((a, b) => a.label.localeCompare(b.label))

  if (sizeOptions.length > 1) {
    sizeOptions.unshift({
      value: ALL_SIZES,
      label: $texts('allSizes', 'All sizes'),
    })
  }

  return sizeOptions
})

const selectedSize = ref(ALL_SIZES)
const imageStyle = defineImageStyle({
  type: 'sizes',
  sizes: {
    sm: 710,
    md: 330,
    lg: 265,
    xxl: 318,
  },
})
</script>

<style lang="scss" scoped>
@import '~/helpers/helpers';

.product-title {
  font-size: between(46px, 72px, 900px, 1300px);
  line-height: between(60px, 80px, 900px, 1300px);
}
</style>
