type ExtractedLocale = {
  langcode?: string
  country?: string
}

export const getLocaleFromPath = (path: string): ExtractedLocale => {
  // Get the first part of the path (e.g. 'en-US').
  const prefix = path.split('/').filter(Boolean)[0]
  if (!prefix) {
    return {}
  }

  let langcode
  let country

  const lastHyphen = prefix.lastIndexOf('-')
  if (lastHyphen === -1) {
    langcode = prefix
  } else {
    langcode = prefix.substring(0, lastHyphen)
    country = prefix.substring(lastHyphen + 1)
  }

  if (langcode === '_nuxt' || langcode === 'api' || langcode === '__cart') {
    // happens if some files are wrongly requested at the frontend or they are not available although they should.
    langcode = undefined
  }

  return {
    langcode,
    country,
  }
}
